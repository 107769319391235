/* Home.css */

.home {
  /* Home component styles */
  overflow: hidden;
}

.blur {
  filter: blur(10px);
}

.background-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%; 
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.background-image,
.foreground-image {
  animation: fadeIn 0.5s ease-in-out forwards;
}


@media (max-width: 768px) {
  .home-text {
    width: 80%;
    font-size: 22px;
  }
}