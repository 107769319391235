/* Header.css */

.header {
    position: absolute;
    color: #ffffff;
    width: 100%;
    z-index: 10;
    padding: 20px 0;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.favicon {
    display: flex;
    align-items: center;
}

.favicon img {
    width: 40px;
    height: auto;
    margin-left: 50vw;
    transform: translateX(-50%);
}

.nav-links {
    position: fixed;
    right: 0;
    padding-right: 5%;
}

.header-footer-hidden .header {
    opacity: 0;
    visibility: hidden;
}

.header-footer-visible .header {
    opacity: 1;
    visibility: visible;
}

.header-hidden {
    opacity: 0;
    visibility: hidden;
}

.header-visible {
    opacity: 1;
    visibility: visible;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    /* Change to flex-start */
}

.menu-icon {
    display: none;
    /* Hide the menu icon initially for larger devices */
    cursor: pointer;
    color: #ffffff;
}

.menu-icon span {
    height: 3px;
    width: 25px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
}


.overlay {
    display: none;
    /* Hide the overlay initially for larger devices */
}


nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

nav ul li {
    display: inline;
    margin-right: 30px;
    transition: opacity 0.3s ease;
    opacity: 1;
}

nav ul li a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0em;
    font-size: 18px;
    opacity: 1;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

nav ul:hover li:not(:hover) {
    opacity: 0.3;
    /* Reduce the opacity of all other nav elements when a specific nav element is being hovered */
}

nav ul li:hover {
    opacity: 1;
    /* Keep the opacity of the hovered element at 1 */
}

nav ul li:hover a {
    color: white;
    text-decoration: underline;
    text-underline-offset: 8px;
}

/* Makes sure the clicked element changes color */
nav ul li a.active {
    color: white;
    text-decoration: underline;
    text-underline-offset: 8px;
}


nav ul li:last-child {
    margin-right: 0;
}



@media (max-width: 768px) {

    /* Add dark grey background color to the header for small devices and adjusts the height with padding */
    .nav-container {
        background-color: rgba(0, 0, 0, 1.0);
        position: fixed;
        padding: 15px; /* determines height of nav bar additionally to content size*/
        z-index: 1;
    }

    /* Compensate for changes in the size of av-container due to changing nav element size */
    .favicon img {
        margin-left: calc(50vw - 15px);
        margin-top: 5px;
    }

    
    /* Styling of menu icon */
    .menu-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        /* Align the menu icon to the right */
        right: 0;
        padding-right: 12px;

        /* Adjust the top position as needed */

        /* Center the menu icon vertically */
        position: fixed;
        transform: translateX(-50%);
        z-index: 3;
        /* ensure the menu button is on top */
    }

    /* Styling of nav elements */
    .nav-links {
        opacity: 0;
        transform: translateX(-100%);
        visibility: hidden;
        transition: none;
        /* No transition by default */
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding-top: 100px;
        padding-left: 25px;
        align-items: flex-start;
        /* Center the menu icon vertically */
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1;
        flex-direction: column;
        display: flex;
        /* Adds a background blur to the overlay */
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
    }

    .menu-open .nav-links {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        /* Move in from the right */

    }

    .menu-closed .nav-links {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        /* Move off-screen to the right */

    }

    .transition-enabled {
        transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s linear;
        /* Add other transition properties as needed */
    }

    .no-transition {
        transition: none;
        /* No transition TODO: Animation still triggered if device size changes */
    }


    .nav-links ul {
        flex-direction: column;
    }

    .nav-links li {
        margin-bottom: 20px;
    }

    .menu-open .menu-icon span:nth-child(1) {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .menu-open .menu-icon span:nth-child(2) {
        opacity: 0;
    }

    .menu-open .menu-icon span:nth-child(3) {
        transform: rotate(45deg) translate(-5px, -6px);
    }

    .overlay {
        display: none;
        /* Hide the overlay initially for smaller devices */
        pointer-events: none;
        /* Make the overlay non-interactive when hidden */
        z-index: 2;
        /* start with 0 opacity */
        transition: opacity 0.5s ease;
    }

    .menu-open .overlay {
        display: block;
        /* Show the overlay when the menu is open on smaller devices */
        opacity: 1;
        z-index: 2;
        pointer-events: auto;
    }

    .nav-links ul li a {
        font-size: 30px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0em;

        text-transform: none;
        font-family: 'Poppins', sans-serif;
    }
}