/* ImageModal.css */

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  opacity: 0;
  /* Start with opacity 0 */
  transition: opacity 0.3s ease;
  /* Add transition for opacity */
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.image-modal-content {
  position: relative;
  width: 90vw;
  height: 90vh;
  overflow: hidden;
  transform: scale(0.5);
  /* Start with scaled-down size */
  transition: transform 0.3s ease;
  /* Add transition for transform */
}

.image-modal-overlay.active {
  opacity: 1;
  /* Apply opacity 1 when active */
}

.image-modal-content.active {
  transform: scale(1);
  /* Apply full scale when active */
}

.enlarged-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.enlarged-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.close-button {
  position: fixed;
  /* Change to fixed positioning */
  top: 24px;
  right: 24px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  z-index: 1;
  /* Ensure the close button is above the overlay content */
}

.show-more-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  z-index: 100;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  margin-bottom: 5px;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  /* Change border-bottom to border-top */
  margin-bottom: 5px;
  /* Adjust margin to maintain spacing */
}

.more-info {
  position: absolute;
  bottom: 80px;
  /* Position it below the button */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  max-width: 40%;
  z-index: 100;
  /* Ensure it's above other content */
  overflow-wrap: break-word;

  overflow-y: auto;
  max-height: 70vh;
  padding-right: 10px;
}

.markdown-text p {
  font-size: 16px;
  font-family: 'Poppins', sans-serif; /* Use the Poppins font family */
}

/* Style the link */
a {
  color: rgba(255, 255, 255, 0.9);
}


@media (max-width: 768px) {
  .more-info {
    max-width: 100%;
  }
}