/* About.module.css */

.about {
  display: flex;
  justify-content: center;
  margin-top: 4%;
  margin-bottom: 5%;
}

/* Style the link */
a {
  color: rgba(64,64,64,.9);
}

.text-wrapper h2 {
  color: white; /* Set text color to white */
  font-family: 'Poppins', sans-serif; /* Use the Poppins font family */
  font-weight: 700; /* Set font weight to 500 */
  font-size: 35px;
  font-style: italic;
}

.route-info-container h2 {
  color: white; /* Set text color to white */
  font-family: 'Poppins', sans-serif; /* Use the Poppins font family */
  font-weight: 700; /* Set font weight to 500 */
  font-size: 35px;
  font-style: italic;
}

/*
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 100%); */
/* } */


.map-container,
.route-info-container {
  /* Make sure content does not exceed device */
  width: 45%;
}

.image-container {
  box-sizing: border-box;
  /* Include padding and border in the width */
  width: 45%;
  /* Subtract padding (4% left + 2% right) */
}

.text-wrapper {
  width: 120%;
}

.map-container,
.text-wrapper {
  margin-left: 4%;
  margin-right: 2%;
}

.route-info-container,
.image-container {
  margin-right: 4%;
  margin-left: 2%;
}

.map-container {
  z-index: 1;
}

.route-info-container p,
.text-wrapper p {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 15px;
  font-style: normal;
  line-height: 1.4em;
  letter-spacing: .03em;
}

.horizontal-line {
  width: 100%;
  height: 2px;
  background-color: #333;
}

/* New styles for the image section */
.about-image-section {
  display: flex;
  justify-content: center;
  padding-top: 10vh;
  margin-bottom: 4%;
}

.image-container img {
  max-width: 100%;
  height: auto;
  margin-top: 30px;
}

@media (max-width: 768px) {
  .about {
    flex-direction: column-reverse;
    margin-bottom: 10%;
    padding-top: 20px;
  }

  .image-container img {
    margin-top: 0px;
  }

  .map-container,
  .route-info-container,
  .text-wrapper {
    width: 84%;
    padding-left: 8%;
    /* Adjust the percentage for padding as needed */
    padding-right: 8%;
    /* Adjust the percentage for padding as needed */
  }

  .image-container {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }

  .map-container,
  .route-info-container,
  .text-wrapper,
  .image-container {
    margin: 0;
  }

  .text-wrapper {
    padding-top: 5%;
  }

  .map-container {
    margin-top: 5%;
  }

  /* New styles for the image section in smaller devices */
  .about-image-section {
    flex-direction: column-reverse;
    padding-bottom: 20px;
  }
}
