.App {
  /* Setup */
  background-color: rgb(15, 15, 15, 0.9); /* Dark gray color */
}

body {
  background-color: rgb(15, 15, 15, 1.0); /* Dark gray color */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
