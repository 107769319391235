/* LoadingSpinner.css */
.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgb(15, 15, 15, 0.9); /* Dark gray color */
}

.spinner {
    border: 4px solid rgb(15, 15, 15, 0.1); /* Dark gray color */
    border-top: 4px solid #333;
    /* Set the color of the spinner */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    /* Add a spinning animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}