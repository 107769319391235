.image-container {
  text-align: center;
  width: 100%;
  padding: 0; /* Remove padding to prevent extra width */
  margin: 0; /* Remove margin to prevent extra width */
}


.image-container:hover .image {
  filter: brightness(60%);
}

.image {
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.blur {
  filter: blur(10px);
}


p {
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 20px;
  font-style: normal;
  line-height: 1.4em;
  letter-spacing: .03em;
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
}