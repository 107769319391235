/* Add this to your CSS file */
.portfolio-grid-wrapper {
    /* Adjust the value as needed */
    background-color: rgb(15, 15, 15, 0.9);
    /* Dark gray color */
    overflow: hidden;
    /* Hide content overflowing the wrapper */
    box-sizing: border-box;

    padding-top: 98px;
    margin-bottom: 55px;

    /* TODO: Use styling in .js file to be able to use constants */
    padding-left: 24px;
    padding-right: 24px;
}