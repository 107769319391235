.portfolio-grid-detail-wrapper {
    background-color: rgb(15, 15, 15, 0.9);
    /* Padding of the entire container */
    /* Ensure the background covers the entire viewport height */
    padding-top: 98px;
    /* TODO: style={{ paddingLeft: {GUTTER_VALUE}, paddingRight: {GUTTER_VALUE} }} */
    margin-bottom: 90px;

    padding-left: 24px;
    padding-right: 24px;
}

.scrolling-down .portfolio-grid-detail-wrapper:not(.modal-open) .back-button.changeOpacity {
    opacity: 0.5;
    transition: opacity 0.5s ease;
  }
  
  .portfolio-grid-detail-wrapper:not(.modal-open) .back-button.original {
    opacity: 1;
    transition: opacity 0.5s ease;
  }

.back-button {
    position: fixed;
    top: 20px;
    left: 24px;
    z-index: 20;
    margin: 0;
    padding: 10px;
    font-size: 12px;
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    cursor: pointer;
}

.portfolio-grid-detail-wrapper.modal-open .back-button {
    opacity: 0;
    pointer-events: none;
}

.portfolio-grid-detail-wrapper.modal-closed .back-button {
    opacity: 1;
}