/* Footer styles */
.footer {
    background-color: rgba(51, 51, 51, 0.7);
    color: #fff;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    /* Ensure it's above other content */
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.header-footer-hidden .footer {
    opacity: 0;
    visibility: hidden;
}

.header-footer-visible .footer {
    opacity: 1;
    visibility: visible;
}

.footer-hidden {
    opacity: 0;
    visibility: hidden;
}

.footer-visible {
    opacity: 1;
    visibility: visible;
}

.instagram-section+.instagram-section {
    margin-left: 20px;
}

.footer:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: -1;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

/* Footer content styles */
.footer-content {
    display: flex;
    /* Display content and Instagram icon horizontally */
    align-items: center;
    /* Center vertically within the footer */
    justify-content: center;
    /* Center horizontally within the footer */
}


/* Instagram icon styles */
.instagram-link {
    text-decoration: none;
    color: #fff;
}

/* Style the Instagram icon as needed */
.instagram-link svg {
    fill: #fff;
    transition: opacity 0.3s;
    margin-top: 25px;
}

/* Change the opacity on hover */
.instagram-link:hover svg {
    opacity: 0.3;
    cursor: pointer;
}

.follow-text {
    margin-right: 0.5rem;
    color: #fff;
    font-weight: 100;
    font-size: 30px;
}

.follow-section {
    display: flex;
    align-items: center;
}

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&display=swap');

/* Additional text styles */
.hello-text {
    font-family: 'Dancing Script', cursive;
    color: #fff;
    font-size: 15px;
    margin-top: 5px;
}